<template>
    <about-us/>
</template>

<script>
import AboutUs from "../components/AboutUs/index"
export default {
    components: {
        AboutUs
    }
}
</script>

<style>

</style>