<template>
          <!-- Contact Start -->
        <section class="contact-section">
            <div class="container">
                <div class="row">
                    <div class="col-md-4">
                        <div class="contact--info-area">
                            <h3>Get in touch</h3>
                            <p>
                                Looking for help? Fill the form and get started.
                            </p>
                            <div class="single-info">
                                <h5>Headquaters</h5>
                                <p>
                                    <i class="fal fa-home"></i>
                                  Tekeli Fevzi Çakmak, 10032. Sk. No 2, 35865 Itob <br />Osb/Menderes/Izmir - TURKEY
                                </p>
                            </div>
                            <div class="single-info">
                                <h5>Support</h5>
                                <p>
                                    <i class="fal fa-envelope"></i>
                                    info@fitudy.com
                                </p>
                            </div>
                            <div class="ab-social">
                                <h5>Follow Us</h5>
                                <a class="fac" href="#"><i class="fab fa-facebook-f"></i></a>
                                <a class="twi" href="#"><i class="fab fa-twitter"></i></a>
                                <a class="you" href="#"><i class="fab fa-youtube"></i></a>
                                <a class="lin" href="#"><i class="fab fa-linkedin-in"></i></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="contact-form">
                            <h4 class="mb-4">Let’s Connect</h4>
                            <form  class="row">
                                <div class="col-md-6">
                                    <input type="text" name="f-name" placeholder="First Name">
                                </div>
                                <div class="col-md-6">
                                    <input type="text" name="l-name" placeholder="Last Name">
                                </div>
                                <div class="col-md-6">
                                    <input type="email" name="email" placeholder="Email Address">
                                </div>
                                <div class="col-md-6">
                                    <input type="number" name="phone" placeholder="Phone Number">
                                </div>
                                <div class="col-md-12">
                                    <input type="text" name="suject" placeholder="Subject">
                                </div>
                                <div class="col-md-12">
                                    <textarea name="message" placeholder="How can we help?"></textarea>
                                </div>
                                <div class="col-md-6 text-right">
                                    <input type="submit" name="submit" value="Send Message">
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- Contact End -->
</template>

<script>
export default {

}
</script>

<style>

</style>
