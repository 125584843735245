<template>
  <section class="appie-services-2-area pt-90 pb-55" id="service">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="appie-single-service-2 appie-single-service-about mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="200ms">
                        <div class="icon">
                            <i class="fal fa-camera"></i>
                        </div>
                        <h4 class="title">Height and Weight Info</h4>
                        <p>Open the app, enter your height and weight information.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="appie-single-service-2 appie-single-service-about item-2 mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="400ms">
                        <div class="icon">
                            <i class="fal fa-camera"></i>
                        </div>
                        <h4 class="title">Body Analyze</h4>
                        <p>In the next step, get in front of the camera and upload the desired photos.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="appie-single-service-2 appie-single-service-about item-3 mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="600ms">
                        <div class="icon">
                            <i class="fal fa-list"></i>
                        </div>
                        <h4 class="title">Program Selection</h4>
                        <p>It follows your program with advanced artificial intelligence and supports your development.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="appie-single-service-2 appie-single-service-about item-4 mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="200ms">
                        <div class="icon">
                            <i class="fal fa-running"></i>
                        </div>
                        <h4 class="title">Let's Begin</h4>
                        <p>Follow the directions and don't interrupt your workouts.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="appie-single-service-2 appie-single-service-about item-5 mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="400ms">
                        <div class="icon">
                            <i class="fal fa-ribbon"></i>
                        </div>
                        <h4 class="title">Completed Program</h4>
                        <p>After the program, upload your photos again as directed.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="appie-single-service-2 appie-single-service-about item-6 mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="600ms">
                        <div class="icon">
                            <i class="fal fa-analytics"></i>
                        </div>
                        <h4 class="title">Performance Analyze</h4>
                        <p>The artificial intelligence infrastructure continues to guide you by analyzing your before and after situation.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>
