<template>
  <div>
    <!--====== PRELOADER PART START ======-->

    <!-- <div class="loader-wrap">
        <div class="preloader"><div class="preloader-close">Preloader Close</div></div>
        <div class="layer layer-one"><span class="overlay"></span></div>
        <div class="layer layer-two"><span class="overlay"></span></div>
        <div class="layer layer-three"><span class="overlay"></span></div>
    </div> -->

    <!--====== PRELOADER PART ENDS ======-->

    <!--====== OFFCANVAS MENU PART START ======-->

    <!--====== OFFCANVAS MENU PART ENDS ======-->

    <!--====== PART START ======-->

    <sidebar-home-one
        :sidebar="sidebar"
        @toggleSidebar="toggleSidebar"
        :menuItems="navs"
    />

    <header-home-six @toggleSidebar="toggleSidebar" :menuItems="navs" />

    <!--====== PART ENDS ======-->

    <!--====== APPIE HERO PART START ======-->

    <hero-home-six />

    <!--====== APPIE HERO PART ENDS ======-->

    <!--====== APPIE SERRVICE 2 PART START ======-->

    <counter-home-two
        title=""
        description=""
    />


    <services-home-two
      class="pt-90 pb-0"
      title="How does it work"
      description="Choose from a wide range of workout and fitness classes designed for all experience levels."
    />


    <!--====== APPIE SERRVICE 2 PART ENDS ======-->

    <!--====== APPIE FEATURES PART START ======-->

    <features-home-one class="pb-80 appie-features-6-area" />

    <!--====== APPIE FEATURES PART ENDS ======-->

    <!--====== APPIE COUNTER PART START ======-->



    <footer-home-one />

    <!--====== APPIE FOOTER PART ENDS ======-->

    <!--====== APPIE BACK TO TOP PART ENDS ======-->
    <div class="back-to-top">
      <a href="#"><i class="fal fa-arrow-up"></i></a>
    </div>
    <!--====== APPIE BACK TO TOP PART ENDS ======-->
  </div>
</template>

<script>
import blogImgFour from "@/assets/images/blog-4.jpg";
import blogImgFive from "@/assets/images/blog-5.jpg";
import blogImgSix from "@/assets/images/blog-6.jpg";
import blogImgSeven from "@/assets/images/blog-7.jpg";
import video_bg_thumb from "@/assets/images/video-thumb-1.jpg";
import HeaderHomeSix from "./HeaderHomeSix.vue";
import HeroHomeSix from "./HeroHomeSix.vue";
import ServicesHomeTwo from "./ServicesHomeTwo.vue";
import FeaturesHomeOne from "./FeaturesHomeOne.vue";
import FooterHomeOne from "./FooterHomeOne.vue";
import CounterHomeTwo from "./CounterHomeTwo.vue";
import SidebarHomeOne from "./SidebarHomeOne";

export default {
  components: {
    SidebarHomeOne,
    HeaderHomeSix,
    HeroHomeSix,
    ServicesHomeTwo,
    FeaturesHomeOne,
    FooterHomeOne,
    CounterHomeTwo
  },
  data() {
    return {
      sidebar: false,
      video_bg_thumb: video_bg_thumb,
      blogs: [
        {
          img: blogImgFour,
          title: "How to Improve Your App Store Position",
          date: "July 14, 2022",
          comment_date: "July 14, 2022",
        },
        {
          img: blogImgFive,
          title: "Introducing New App Design for our iOS App",
          date: "July 14, 2022",
          comment_date: "July 14, 2022",
        },
        {
          img: blogImgSix,
          title: "Engineering job is Becoming More interesting.",
          date: "July 14, 2022",
          comment_date: "July 14, 2022",
        },
        {
          img: blogImgSeven,
          title: "20 Myths About Mobile Applications",
          date: "July 14, 2022",
          comment_date: "July 14, 2022",
        },
      ],
      navs: [
        {
          name: "home",
          path: "/",
        },
        {
          name: "About",
          path: "/about-us",
        },
        {
          name: "contact",
          path: "/contact",
        },
      ],
    };
  },
  mounted() {
    document.addEventListener("scroll", this.topToBottom);
  },
  methods: {
    topToBottom() {
      const result = document.querySelector(".back-to-top");
      if (
        document.body.scrollTop > window.innerHeight ||
        document.documentElement.scrollTop > window.innerHeight
      ) {
        result.style.display = "block";
      } else {
        result.style.display = "none";
      }
    },
    toggleSidebar() {
      this.sidebar = !this.sidebar;
    },
  },
};
</script>

<style>
</style>
