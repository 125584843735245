import { render, staticRenderFns } from "./HeaderHomeSix.vue?vue&type=template&id=37dc1dfe&"
import script from "./HeaderHomeSix.vue?vue&type=script&lang=js&"
export * from "./HeaderHomeSix.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports