<template>
  <div>
    <!--====== PRELOADER PART START ======-->

    <!-- <div class="loader-wrap">
        <div class="preloader"><div class="preloader-close">Preloader Close</div></div>
        <div class="layer layer-one"><span class="overlay"></span></div>
        <div class="layer layer-two"><span class="overlay"></span></div>
        <div class="layer layer-three"><span class="overlay"></span></div>
    </div> -->

    <!--====== PRELOADER PART ENDS ======-->

    <!--====== OFFCANVAS MENU PART START ======-->

    <sidebar-home-one
      :sidebar="sidebar"
      @toggleSidebar="toggleSidebar"
      :menuItems="navs"
    />
    <!--====== OFFCANVAS MENU PART ENDS ======-->

    <!--====== PART START ======-->

    <header-contact @toggleSidebar="toggleSidebar" :menuItems="navs" />

    <!--====== PART ENDS ======-->

    <!--====== APPIE PAGE TITLE PART START ======-->

    <!-- reference by news -->
    <title-area />

    <!--====== APPIE PAGE TITLE PART ENDS ======-->

    <!-- Contact Start -->
    <contact-inputs />
    <!-- Contact End -->

    <!-- Gamps Start -->
    <div class="bisylms-map">
      <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12543.819255271543!2d27.2006168!3d38.1877237!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x145f64fb7d46feac!2s%C4%B0zmir%20Bilimpark!5e0!3m2!1str!2str!4v1654639343467!5m2!1str!2str" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
    <!-- Gamps Start -->

    <!--====== APPIE FOOTER PART START ======-->

    <footer-home-one />

    <!--====== APPIE FOOTER PART ENDS ======-->

    <!--====== APPIE BACK TO TOP PART ENDS ======-->
    <div class="back-to-top">
      <a href="#"><i class="fal fa-arrow-up"></i></a>
    </div>
    <!--====== APPIE BACK TO TOP PART ENDS ======-->
  </div>
</template>

<script>
import FooterHomeOne from "../HomeSix/FooterHomeOne.vue";
import SidebarHomeOne from "../HomeSix/SidebarHomeOne.vue";
import TitleArea from "../News/TitleArea.vue";
import ContactInputs from "./ContactInputs.vue";
import HeaderContact from "./HeaderContact.vue";
export default {
  components: {
    SidebarHomeOne,
    HeaderContact,
    TitleArea,
    ContactInputs,
    FooterHomeOne,
  },
  data() {
    return {
      sidebar: false,
      navs: [
        {
          name: "home",
          path: "/",
        },
        {
          name: "About",
          path: "/about-us",
        },
        {
          name: "contact",
          path: "/contact",
        },
      ],
    };
  },
  mounted() {
    document.addEventListener("scroll", this.topToBottom);
  },
  methods: {
    topToBottom() {
      const result = document.querySelector(".back-to-top");
      if (
        document.body.scrollTop > window.innerHeight ||
        document.documentElement.scrollTop > window.innerHeight
      ) {
        result.style.display = "block";
      } else {
        result.style.display = "none";
      }
    },
    toggleSidebar() {
      this.sidebar = !this.sidebar;
    },
  },
};
</script>

<style>
</style>
