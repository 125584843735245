<template>
  <!--====== APPIE FOOTER PART START ======-->

  <section class="appie-footer-area">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-6">
          <div class="footer-about-widget">
            <div class="logo">
              <a href="#"><img src="@/assets/images/logo.svg" alt="" /></a>
            </div>
            <p class="pt-0">
              Wherever You Need, Whenever You Need
            </p>
            <div class="social mt-30">
              <ul>
                <li>
                  <a href="#"><i class="fab fa-facebook-f"></i></a>
                </li>
                <li>
                  <a href="#"><i class="fab fa-twitter"></i></a>
                </li>
                <li>
                  <a href="#"><i class="fab fa-linkedin-in"></i></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-2 col-md-6">
          <div class="footer-navigation">
            <h4 class="title">Company</h4>
            <ul>
              <li><router-link to="/about-us">About Us</router-link></li>

            </ul>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="footer-navigation">
            <h4 class="title">&nbsp;</h4>
            <ul>
              <li><router-link to="/contact">Contact</router-link></li>
            </ul>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="footer-widget-info">
            <h4 class="title">Get In Touch</h4>
            <ul>
              <li>
                <a href="#"
                  ><i class="fal fa-envelope"></i> info@fitudy.com</a
                >
              </li>
              <li>
                <a href="#"
                  ><i class="fal fa-map-marker-alt"></i> Tekeli Fevzi Çakmak, 10032. Sk. No 2, 35865 Itob Osb/Menderes/Izmir - TURKEY</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div
            class="
              footer-copyright
              d-flex
              align-items-end
              justify-content-end
              pt-35
            "
          >
            <div class="copyright-text">
              <p>Copyright © 2022 Soho Development. All rights reserved.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!--====== APPIE FOOTER PART ENDS ======-->
</template>

<script>
export default {};
</script>

<style>
</style>
