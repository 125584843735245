<template>
  <div>
    <!--====== PRELOADER PART START ======-->

    <!-- <div class="loader-wrap">
        <div class="preloader"><div class="preloader-close">Preloader Close</div></div>
        <div class="layer layer-one"><span class="overlay"></span></div>
        <div class="layer layer-two"><span class="overlay"></span></div>
        <div class="layer layer-three"><span class="overlay"></span></div>
    </div> -->

    <!--====== PRELOADER PART ENDS ======-->

    <!--====== OFFCANVAS MENU PART START ======-->

    <sidebar-home-one
      :sidebar="sidebar"
      @toggleSidebar="toggleSidebar"
      :menuItems="navs"
    />

    <!--====== OFFCANVAS MENU PART ENDS ======-->

    <!--====== APPIE HEADER PART START ======-->

    <header-home-six @toggleSidebar="toggleSidebar" :menuItems="navs" />

    <!--====== APPIE HEADER PART ENDS ======-->

    <!--====== APPIE HEADER PART ENDS ======-->

    <div class="appie-about-top-title-area">
      <div class="container">
        <div class="row">
          <div class="col-lg-7">
            <div class="appie-about-top-title">
              <h2 class="title">Add movement to your life!</h2>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--====== APPIE HEADER PART ENDS ======-->

    <!--====== APPIE HEADER PART ENDS ======-->

    <section class="appie-about-page-area">
      <div class="container">
        <div class="row">
          <div class="col-lg-8">
            <div class="appie-about-page-content">
              <h3 class="title">
                What is Fitudy ?
              </h3>
              <p>
                Fitudy aims to support the daily physical activity requirements/daily sports routines of people, as well as users with an increasing work pace and busy life pace. Users will have professional and personalized study programs that they can reach with a reasonable budget. It is a lifestyle mobile application that will be supported by artificial intelligence technology and prepared for tracking, development and support of sports habits.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!--====== APPIE HEADER PART ENDS ======-->

    <!--====== APPIE SERRVICE 2 PART START ======-->

    <service-aboutus />

    <!--====== APPIE SERRVICE 2 PART ENDS ======-->

    <!--====== APPIE TEAM PART START ======-->


    <!--====== APPIE TEAM PART ENDS ======-->

    <!--====== APPIE SPONSER PART START ======-->

    <sponser-home-two
      title="Appie works best with"
      more_title="your favorite platforms"
      description="Join over 40,000 businesses worldwide."
    />

    <!--====== APPIE SPONSER PART ENDS ======-->

    <!--====== APPIE SPONSER PART ENDS ======-->
    <get-started />

    <!--====== APPIE SPONSER PART ENDS ======-->

    <!--====== APPIE FOOTER PART START ======-->

    <footer-home-one class="appie-footer-area appie-footer-about-area" />

    <!--====== APPIE FOOTER PART ENDS ======-->

    <!--====== APPIE BACK TO TOP PART ENDS ======-->
    <div class="back-to-top">
      <a href="#"><i class="fal fa-arrow-up"></i></a>
    </div>
    <!--====== APPIE BACK TO TOP PART ENDS ======-->
  </div>
</template>

<script>
import GetStarted from "./GetStarted.vue";
import ServiceAboutus from "./ServiceAboutus.vue";
import FooterHomeOne from "../HomeSix/FooterHomeOne";
import HeaderHomeSix from "../HomeSix/HeaderHomeSix";

export default {
  components: {
    ServiceAboutus,
    GetStarted,
    FooterHomeOne,
    HeaderHomeSix
  },
  data() {
    return {
      sidebar: false,

      navs: [
        {
          name: "home",
          path: "/",
        },
        {
          name: "About",
          path: "/about-us",
        },
        {
          name: "contact",
          path: "/contact",
        },
      ],
    };
  },
  mounted() {
    document.addEventListener("scroll", this.topToBottom);
  },
  methods: {
    topToBottom() {
      const result = document.querySelector(".back-to-top");
      if (
        document.body.scrollTop > window.innerHeight ||
        document.documentElement.scrollTop > window.innerHeight
      ) {
        result.style.display = "block";
      } else {
        result.style.display = "none";
      }
    },
    toggleSidebar() {
      this.sidebar = !this.sidebar;
    },
  },
};
</script>

<style>
</style>
